import * as React from "react";
import { Link } from "gatsby";
import { Icon } from "@iconify/react";
import "../assets/css/error404.css";
import logo from "../assets/img/getNewsletterImg.png";
import errorPicture from "../assets/img/Error404Picture.svg";
function NotFoundPage() {
  function handleClick() {
    window.history.back();
  }
  return (
    <div className="error-404-box">
      <div className="error-404-header">
        <img src={logo} alt="" style={{width: "20%"}}/>
        <button onClick={handleClick}>
          <Icon icon="akar-icons:chevron-left" />
          Back
        </button>
      </div>
      <div className="error-404-title">
        <h1>Ups!</h1>
        <p>Error</p>
        <img src={errorPicture} alt="imagen de gatitos" />
      </div>

      <div className="error-404-redirect">
        <a href="/">
          <Icon icon="fluent:home-20-regular" />
          Home
        </a>
        <Link to={process.env.GATSBY_ACCESS_URL}>
          <Icon icon="fluent:design-ideas-16-regular" />
          Tool
        </Link>
      </div>
    </div>
  );
}
export default NotFoundPage;

export const Head = () => <title>Not found</title>;
